import React from "react";
import { navigate } from "gatsby";
import { Button } from ".";
import { useClasses } from "@hooks";
import arrowEnterImg from "@assets/icons/arrow-enter.svg";

export const ButtonPrimary: typeof Button = ({
  className,
  children,
  ...otherProps
}) => {
  return (
    <Button
      className={useClasses(["buttonPrimary", className])}
      {...otherProps}
    >
      {children}
    </Button>
  );
};

export const ButtonAnchor: typeof Button = ({
  href,
  className,
  children,
  ...otherProps
}) => (
  <Button
    href={href}
    className={useClasses(["buttonAnchor", className])}
    {...otherProps}
  >
    {children}
  </Button>
);

export const ButtonCreateYourLantern: typeof Button = ({
  children,
  ...otherProps
}) => (
  <ButtonPrimary
    className="buttonCreateYourLantern"
    iconBefore={arrowEnterImg}
    onClick={() => navigate("/create")}
    {...otherProps}
  >
    <span>CREATE YOUR LANTERN</span>
  </ButtonPrimary>
);

export const ButtonSeeMyLantern: typeof Button = ({
  children,
  errorMessage = null,
  ...otherProps
}) => (
  <ButtonPrimary
    data-title={errorMessage}
    className="buttonSeeMyLantern"
    iconBefore={arrowEnterImg}
    {...otherProps}
  >
    <span>NEXT</span>
  </ButtonPrimary>
);

export const ButtonReadyToFly: typeof Button = ({
  children,
  errorMessage = null,
  ...otherProps
}) => (
  <ButtonPrimary
    data-title={errorMessage}
    className="buttonReadyToFly"
    iconBefore={arrowEnterImg}
    {...otherProps}
  >
    <span>RELEASE LANTERN</span>
  </ButtonPrimary>
);
