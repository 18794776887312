import React from "react";
import { Layout } from "@components/Layout";
import ShowLantern from "@components/ShowLantern";
import firebase from "gatsby-plugin-firebase";
import { ButtonAnchor } from "@components/Button/types";

const ErrorMessage = ({ lanternId, lantern }) => {
  const errorMessage =
    lanternId && lantern === undefined
      ? "Loading..."
      : lanternId && lantern === null
      ? "We couldn't find this lantern :("
      : !lanternId && lantern === undefined
      ? "Wrong URL... Sorry"
      : lantern.active === false
      ? "Sorry, this lantern has been deleted"
      : null;

  if (!errorMessage) {
    return null;
  }
  return (
    <div className="showLanternError">
      <div className="errorTitle">{errorMessage}</div>
      <ButtonAnchor href="/lanterns">Explore virtual lanterns</ButtonAnchor>
    </div>
  );
};

function ViewLantern(props) {
  const [lantern, setLantern] = React.useState(undefined);

  const lanternId = props.location?.search?.substring(1);

  React.useEffect(() => {
    firebase
      .database()
      .ref(`/lanterns/${lanternId}`)
      .once("value")
      .then((snapshot) => {
        setLantern(snapshot.val());
      });
  }, []);

  return (
    <Layout
      currentPath={props.location.pathname}
      className="viewLantern"
      withGoBackCross
      lanternsBackground
    >
      <ErrorMessage lanternId={lanternId} lantern={lantern} />
      {lantern && lantern.active && (
        <ShowLantern
          showSender
          lantern={lantern}
          textToCopy={window.location.href}
        />
      )}
    </Layout>
  );
}

export default ViewLantern;
